<template>
  <!-- 保养计划弹窗 -->
  <el-dialog
    title="保养计划"
    :visible.sync="visible"
    custom-class="planDialog dataM"
    width="320px"
    :close-on-click-modal="false"
    @close="handleClose"
    center
    >
    <div class="dialogContent">
      <div class="item">
        <label>保养时间：</label>
        <span>{{planData.maintenancePlan}}</span>
      </div>
      <div class="item">
        <label>保养时长：</label>
        <span>{{planData.maintenanceDuration}}</span>
      </div>
      <div class="item maintenance">
        <label>保养项目：</label>
        <div class="maintenanceItems" v-if="planData.maintenanceItems">
          <div v-for="(item,index) in planData.maintenanceItems" :key="index">{{item}}</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" style="width:80%" @click="handleClose()">知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name:'planDialog',
  props:{
    planData:{
      require:true,
      default:{}
    },
    dialogVisible:{
      require:true,
      default:false
    }
  },
  data(){
    return{
      // visible:false
    }
  },
  computed:{
    visible:{
      get(){
        return this.dialogVisible
      },
      set(){
        
      }
    }
  },
  methods:{
    handleClose(){
      this.$emit('update:dialogVisible',false)
    }
  }
}
</script>

<style scoped lang="scss">
.dialogContent{
  .item{
    line-height: 40px;
    label{
      width: 50%;
      display: inline-block;
      text-align: right;
    }
  }
  .maintenance{
    display: flex;
  }
}
</style>
<style lang="scss">
.planDialog{
  .el-dialog__body,.el-dialog__header,.el-dialog__footer{
    padding-right: 0;
    padding-left: 0;
  }
}
</style>